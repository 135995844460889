$primary-color: rgb(251, 95, 10);

$primary-v2-color: rgba(251, 95, 10, 0.8);

$primary-v3-color: rgba(251, 95, 10, 0.6);

$success-color: #52b57f;

$warn-color: #fba30a;

$danger-color: #ff4d4f;

$link-color: #1890ff;

$white-color: #ffffff;

$desc-color: #333333;

$desc-v2-color: #666666;

$desc-v3-color: #999999;

$desc-light: #b2b2b2;

$bg-color: #f4f4f4;

$warn-bg-color: #fefbe8;

$success-bg-color: #f8ffef;

$info-bg-color: #f8f8f8;

$border-color: #e5e5e5;

$brown-color: #928464;

$space-number: .3rem;

$pc-width: 450px;

.pay-result {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;
  background: #fff; 

  .result-icon {
    width: 120px; 
    height: auto;
    margin-bottom: 20px;
  }

  .result-message {
    font-size: 18px;
    color: #333;
    margin-bottom: 20px;
  }

  .big-btn {
    width: 200px;
  }
}
