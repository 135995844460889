$primary-color: rgb(251, 95, 10);

$primary-v2-color: rgba(251, 95, 10, 0.8);

$primary-v3-color: rgba(251, 95, 10, 0.6);

$success-color: #52b57f;

$warn-color: #fba30a;

$danger-color: #ff4d4f;

$link-color: #1890ff;

$white-color: #ffffff;

$desc-color: #333333;

$desc-v2-color: #666666;

$desc-v3-color: #999999;

$desc-light: #b2b2b2;

$bg-color: #f4f4f4;

$warn-bg-color: #fefbe8;

$success-bg-color: #f8ffef;

$info-bg-color: #f8f8f8;

$border-color: #e5e5e5;

$brown-color: #928464;

$space-number: .3rem;

$pc-width: 450px;

.product-detail-wrapper{
  background: #f4f4f4;
  font-size: 14px;
  .photos-wrapper{
    width:100%;
    height:4.5rem;
  }
  .module{
    background: #fff;
    padding: .2rem;
  }
  .base-info{
    .title{
      font-size: 18px;
      font-weight: bold;
      margin-bottom: .1rem;
    }
    .ticket-list{
      .ticket{
        width: 100%;
        margin-top: .2rem;
        padding:.2rem;
        background: #faf1ec;
        box-sizing: border-box;
        border: 1px solid #d7c0ae;
        border-radius: 8px;
      }
      .ticket-image{
        width: 2rem;
        height: 1.2rem;
        border-radius: 8px;
      }
      .info{
       padding-left: .2rem;
      }
      .price{
        justify-content: center;
        align-items: baseline;
      }
      .origin-price{
        margin-right: 5px;
        font-size: 13px;
        color:#999;
        text-decoration: line-through;
      }
      .booking-btn{
        width: 3rem;
        height: .6rem;
        line-height: .6rem;
        text-align: center;
        font-size: 15px;
        color:#835825;
        border-radius: 30px;
        background: linear-gradient(to bottom, #f8dfa6, #f0b859);
      }
    }
  }
  
  .description {
    margin-top: .2rem;
    padding: .3rem .2rem .6rem;
    .desc{
      text-indent: 2em;
    }
  }
}
