$primary-color: rgb(251, 95, 10);

$primary-v2-color: rgba(251, 95, 10, 0.8);

$primary-v3-color: rgba(251, 95, 10, 0.6);

$success-color: #52b57f;

$warn-color: #fba30a;

$danger-color: #ff4d4f;

$link-color: #1890ff;

$white-color: #ffffff;

$desc-color: #333333;

$desc-v2-color: #666666;

$desc-v3-color: #999999;

$desc-light: #b2b2b2;

$bg-color: #f4f4f4;

$warn-bg-color: #fefbe8;

$success-bg-color: #f8ffef;

$info-bg-color: #f8f8f8;

$border-color: #e5e5e5;

$brown-color: #928464;

$space-number: .3rem;

$pc-width: 450px;


.jsbc-order-detail-page{
  padding: .2rem .2rem 1.5rem;
  font-size: 14px;
  background: #f4f4f4;

  .module{
    display: block;
    background: #fff;
    padding:.3rem;
    border-radius: 8px;
    margin-bottom:.2rem;
    overflow: hidden;
    .view {
      padding: .1rem 0;
      display: flex;
      align-items: center;
      .label {
        width: 1.4rem;
        flex-shrink: 0;
        color:#666
      }
      
      .value {
        flex:1;
        padding:0 .15rem;
      }
    }
    .view:last-child{
      padding-bottom: 0;
      border-bottom: 0;
    }
  }
  .module.special{
    border-radius: 0 0 8px 8px;
  }

  .header{
    border-radius: 8px 8px 0 0;
    color:#333;
    background: #fff;
    padding: 0 .3rem;
    overflow: hidden;
    .title {
      font-size: 18px;
      font-weight: bold;
      padding:.2rem 0 0;
      margin-bottom: .1rem;
    }
  }
  .header.status1{
    background: linear-gradient(to bottom, #fcf2e2, #fff);
    border-top: 2px solid #ff9800;
  }
  .header.status2{
    background: linear-gradient(to bottom, #e5fcea, #fff);
    border-top: 2px solid #28a745;
  }
  .header.status3{
    background: linear-gradient(to bottom, #fcedef, #fff);
    border-top: 2px solid #dc3545;
  }
  .header.status4{
    background: linear-gradient(to bottom, #f0f0f0,  #fff);
    border-top: 2px solid #888;
  }
  
  
  .header.status1 .title{
    color: #ff9800 !important; 
  }
  .header.status2 .title {
    color: #28a745 !important; 
  }
  .header.status3 .title{
    color: #dc3545 !important; 
  }
  .header.status4 .title{
    color: #888 !important; 
  }

  .contact-box{
    background: #fff;
    padding:.3rem;
    border-radius: 8px;
    .code{
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  
  
  
}
