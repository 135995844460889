$primary-color: rgb(251, 95, 10);

$primary-v2-color: rgba(251, 95, 10, 0.8);

$primary-v3-color: rgba(251, 95, 10, 0.6);

$success-color: #52b57f;

$warn-color: #fba30a;

$danger-color: #ff4d4f;

$link-color: #1890ff;

$white-color: #ffffff;

$desc-color: #333333;

$desc-v2-color: #666666;

$desc-v3-color: #999999;

$desc-light: #b2b2b2;

$bg-color: #f4f4f4;

$warn-bg-color: #fefbe8;

$success-bg-color: #f8ffef;

$info-bg-color: #f8f8f8;

$border-color: #e5e5e5;

$brown-color: #928464;

$space-number: .3rem;

$pc-width: 450px;

.jsbc-order-page{
  font-size: 14px;
  padding: .2rem .2rem 1.5rem;
  background: linear-gradient(to bottom, #cee6ff, #eef7ff, #f4f4f4);
  box-sizing: border-box;
  .user-info {
    display: flex;
    align-items: center;
    padding: 0.25rem;
    text-align: center;
    .avatar {
      display: block;
      width: 1rem;
      height: 1rem;
      margin-right: 0.3rem;
    }
  
    .phone-number {
      font-size: 16px;
      font-weight: bold;
    }
  }
  
  /* 订单统计 */
  .order-summary {
    display: flex;
    justify-content: space-around;
    background: #fff;
    padding: .3rem;
    border-radius: 8px;
    box-shadow: 0 0.025rem 0.075rem rgba(0, 0, 0, 0.1);
    margin-bottom: .2rem;
  
    .summary-item {
      text-align: center;
      
      .summary-number {
        font-size: 18px;
        font-weight: bold;
      }
      
      .summary-label {
        font-size: 14px;
        color: #666;
      }
    }
  }
  
  .order-card {
    background: #fff;
    margin-top: 0.2rem; 
    padding: .3rem;
    border-radius: 8px;
    box-shadow: 0 0.025rem 0.075rem rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
  }
  
  .order-header {
    display: flex;
    justify-content: space-between;
    color: #333;
    margin-bottom: .2rem;
  }
  
  .order-status {
    font-size: 14px;
  }
  
  .order-status.success {
    color: #28a745; 
  }
  
  .order-status.failed {
    color: #dc3545; 
  }
  
  .order-status.pending {
    color: #ff9800; 
  }
  
  .order-content {
    display: flex;
    align-items: center;
    margin-bottom: 0.125rem;
  
    .order-img {
      width: 1.8rem;
      height: 1.08rem;
      border-radius: 8px;
      margin-right: .2rem;
      object-fit: cover;
    }
  
    .order-info {
      flex: 1;
      
      .order-time {
        font-size: 0.2rem;
        color: #666;
      }
  
      .order-amount {
        font-size: 0.225rem;
        font-weight: bold;
        color: #333;
  
        .amount {
          color: #ff7d00;
        }
      }
    }
  }
  
  .order-detail-btn {
    background: #fff;
    color: #333;
    border:1px solid #e5e5e5;
    font-size: 12px;
    width: 1.8rem;
    height: .6rem;
    line-height: .5rem;
    border-radius: 30px;
    cursor: pointer;
  }

  .order-detail-btn-pay {
    background: linear-gradient(to bottom, #f8dfa6, #f0b859);
    color: #835825;
    border:1px solid #e5e5e5;
    font-size: 12px;
    width: 1.8rem;
    height: .6rem;
    line-height: .5rem;
    border-radius: 30px;
    cursor: pointer;
  }
  
  .no-orders {
    text-align: center;
    padding: 0.5rem;
    color: #999;
  }  
}
