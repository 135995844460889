$primary-color: rgb(251, 95, 10);

$primary-v2-color: rgba(251, 95, 10, 0.8);

$primary-v3-color: rgba(251, 95, 10, 0.6);

$success-color: #52b57f;

$warn-color: #fba30a;

$danger-color: #ff4d4f;

$link-color: #1890ff;

$white-color: #ffffff;

$desc-color: #333333;

$desc-v2-color: #666666;

$desc-v3-color: #999999;

$desc-light: #b2b2b2;

$bg-color: #f4f4f4;

$warn-bg-color: #fefbe8;

$success-bg-color: #f8ffef;

$info-bg-color: #f8f8f8;

$border-color: #e5e5e5;

$brown-color: #928464;

$space-number: .3rem;

$pc-width: 450px;

.jsbc-purchase-page{
  width: 100%;
  font-size: 14px;
  background: #f4f4f4;
  box-sizing: border-box;
  padding:.2rem .2rem 1.6rem;
  .module{
    display: block;
    background: #fff;
    padding:.3rem;
    border-radius: 8px;
    margin-top:.2rem;
    overflow: hidden;
    .view {
      padding: .24rem 0;
      border-bottom: 1px solid #e5e5e5;
      display: flex;
      align-items: center;

      .label {
        width: 80px;
        flex-shrink: 0;
      }
      
      .input {
        flex:1;
        font-size: 15px;
        padding:0 .15rem;
      }
    }
    .view:last-child{
      padding-bottom: 0;
      border-bottom: 0;
    }
  }
  .module:first-child{
    margin-top: 0;
  }
  .title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: .1rem;
  }
  .sec-title{
    font-size: 16px;
    font-weight: bold;
    .fs-12{
      font-weight: normal;
    }
  }
  .price-wrapper{
    position: relative;
    .price-list{
      background: #fff;
      flex-wrap: nowrap;
      overflow-x: scroll;
      margin-bottom: .2rem;
      border-bottom: 1px solid #e5e5e5;
      padding-bottom: .2rem;
      .date {
        position: relative;
        width: 1.40rem;
        height: 1.2rem;
        margin-right: .2rem;
        font-size: 12px;
        background: #f4f4f4;
        padding: .06rem 0;
        flex-shrink: 0;
        text-align: center;
        border-radius: 3px;
        border: 1px solid #f4f4f4;
        box-sizing: border-box;
      }
     
      .selected{
        border: 1px solid #fb5f0a;
        background: #fdf6f3;
      }
      .disabled{
        opacity: .5;
        border: 1px solid #f4f4f4;
        background: #f4f4f4;
      }
    }
    .more-date {
      position: absolute;
      top:0;
      right:0;
      height: 1.2rem;
      background: #fff;
      padding-left: .15rem;
      font-size: 11px;
      .icon-xiangyoujiantou{
        color:#1f90e6 ;
        font-weight: bold;
      }
    }

  }
  
  
 
  
  .flex-row {
    display: flex;
    flex-direction: row;
  }
  
  .center {
    align-items: center;
  }
  
  
  .textarea {
    flex:1;
    padding:0 .15rem;
  }
  
  .count-box {
    display: inline-flex;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #e5e5e5;
    padding: 5px 0;
    margin-left: 10px;
  }
  
  .count-num {
    padding: 0 20px;
    min-width: 40px;
    font-weight: bold;
    text-align: center;
  }
  
  .change-btn {
    display: inline-block;
    padding: 0 20px;
    cursor: pointer;
    user-select: none;
  }
  
  .change-btn.subtract {
    border-right: 1px solid #e5e5e5;
  }
  
  .change-btn.add {
    border-left: 1px solid #e5e5e5;
  }
  
  .bottom-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  
  .total-price {
    color: #fb5f0a;
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }
  
  .big-btn {
    width: 150px;
    height: 45px;
    font-size: 20px;
  }
  .person-form {
    margin-bottom: .4rem;
    .delect{
      font-size: 12px;
      color:#E64340;
      font-weight: normal;
    }
  }

  .person-form:last-child{
    margin-bottom: 0;
  }
  .bottom-box{
    padding:.2rem;
    .booking-btn{
      width: 3rem;
      height: .8rem;
      line-height: .7rem;
      text-align: center;
      font-size: 15px;
      color:#835825;
      border-radius: 30px;
      background: linear-gradient(to bottom, #f8dfa6, #f0b859);
      border:none
    }
    .booking-btn-disabled{
      width: 3rem;
      height: .8rem;
      line-height: .7rem;
      text-align: center;
      font-size: 15px;
      color:#fff;
      border-radius: 30px;
      background: #e5e5e5;
      border:none
    }
  }
}
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.loading-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  // color: #5198ea;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  gap: 16px;
}




