$primary-color: rgb(251, 95, 10);

$primary-v2-color: rgba(251, 95, 10, 0.8);

$primary-v3-color: rgba(251, 95, 10, 0.6);

$success-color: #52b57f;

$warn-color: #fba30a;

$danger-color: #ff4d4f;

$link-color: #1890ff;

$white-color: #ffffff;

$desc-color: #333333;

$desc-v2-color: #666666;

$desc-v3-color: #999999;

$desc-light: #b2b2b2;

$bg-color: #f4f4f4;

$warn-bg-color: #fefbe8;

$success-bg-color: #f8ffef;

$info-bg-color: #f8f8f8;

$border-color: #e5e5e5;

$brown-color: #928464;

$space-number: .3rem;

$pc-width: 450px;


.jsbc-wrapper{
  position: relative;
  background: #fff;
  height: 100vh;
  box-sizing: border-box;
  padding-bottom: 60px;
  .img{
    display: block;
    margin:0 auto;
  }
  .content{
    position: relative;
    z-index: 2;
    height: 10.6rem;
    background: #5aa0e1;
    .logo{
      position: absolute;
      left:0;
      top:.6rem;
      width: 100%;
      z-index: 3;
      .img{
        width: 4.82rem;
        height: .56rem;
      } 
    }
    .title{
      position: absolute;
      left:0;
      top:1.9rem;
      width: 100%;
      z-index: 3;
      .img{
        width: 6.68rem;
        height: 3.36rem;
      }
    }
    .page-bg{
      position: absolute;
      width: 7.5rem;
      left:0;
      bottom:-.05rem;
      height: 10.6rem;
      z-index: 1;
      .img{
        width: 7.5rem;
        height: 10.6rem;
      }
    }
  }

  .footer{
    position: relative;
    width: 100%;
    z-index:5;
    .desc{
      position:relative;
      z-index: 10;
      width: 100%;
      .img{
        width: 5.9rem;
        height: .62rem;
      }
    }
    .btn-box{
      position:relative;
      z-index: 10;
      width: 100%;
      margin-top:.4rem;
      .img{
        width: 3.2rem;
        height: .66rem;
      }
    }
  }

  /* 兼容iphone5，se */
  @media only screen 
  and (min-device-width : 320px) 
  and (max-device-height : 568px) 
  {
    
  }

  /* 兼容iphone6 Plus,iphone7 Plus,iphone8 Plus  */
  @media only screen 
  and (min-device-width : 414px) 
  and (max-device-height : 736px) 
  {
    
  }

  /* 兼容iphone6，iphone7，iphone8 */
  @media only screen 
  and (min-device-width : 375px) 
  and (max-device-height : 667px) 
  {
    
  }


  /* 兼容iphoneX  */
  @media only screen 
  and (min-device-width : 375px) 
  and (device-height:812px) 
  {

    .content{
      height: 11.44rem;
      .logo{top:1.1rem;}
      .title{top:2.80rem;}
    }
    .footer{
      .desc{margin-top:.3rem}
    }
  
  }
  @media only screen 
  and (min-device-width : 375px) 
  and (device-height:724px) 
  {
    .content{
      height: 10.6rem;
    }
    
  }
  /* 兼容iphoneXr/Xs  */
  @media only screen 
  and (min-device-width : 414px) 
  and (device-height:896px) 
  {
    .content{
      height: 11.44rem;
      .logo{top:1.1rem;}
      .title{top:2.80rem;}
    }
    .footer{
      .desc{margin-top:.3rem}
    }
    
  }
  @media only screen 
  and (min-device-width : 414px) 
  and (device-height:814px) 
  {

    .content{
      height: 11.44rem;
      .logo{top:1.1rem;}
      .title{top:2.80rem;}
    }
    .footer{
      .desc{margin-top:.3rem}
    }
    
  }

  
}
